import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import apiClient from "./services/api";

const PrivateRoutes = () => {
  // const [token, setToken] = useState();
  // const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  let token = secureLocalStorage.getItem("token");
  let user = secureLocalStorage.getItem("user");

  const navigate = useNavigate();

  useEffect(() => {
    apiClient
      .get("/validate-token", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        navigate("/login");
      });
  }, []);

  if (!loading) {
    return token && user ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
