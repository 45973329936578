import { React, useState } from "react";
import { TextInput } from "flowbite-react";
import apiClient from "../services/api";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();

  // const [error, setError] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // ({ email, password });
    axios
      .get("https://dev.api.community.urrofilms.com/sanctum/csrf-cookie")
      .then((response) => {
        apiClient
          .post("/login", {
            email: email,
            password: password,
          })
          .then((response) => {
            secureLocalStorage.setItem("token", response.data?.token);
            secureLocalStorage.setItem("user", response.data?.user);

            navigate("/");
          })
          .catch((err) => {
            // setError(err.response.data.message)
            toast({
              title: err.response.data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          });
      });
  };

  return (
    <>
      <div className="px-4 md:px-0 h-screen flex justify-center items-center">
        <div className="w-full md:flex md:justify-center">
          <div className="m-auto">
            <div>
              <a href="https://urroacademy.com/">
                <img
                  className="h-12 w-auto"
                  src="https://urroacademy.com/assets/member/img/u.png"
                  alt="Workflow"
                />
              </a>
              <h2 className="mt-6 text-3xl font-extrabold text-login">
                Mari Kita Diskusi.
              </h2>
            </div>
            <form
              className="mt-8 py-4 px-8 md:p-10 bg-dark flex flex-col md:w-96 gap-4 m-auto justify-center m-auto rounded-2xl"
              onSubmit={handleSubmit}
            >
              <div className="space-y-6">
                <div className="space-y-2">
                  <div className="mb-2 block text-white text-base">
                    <h1>Email Address / Username</h1>
                  </div>
                  <TextInput
                    id="email1"
                    type="text"
                    required={true}
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div className="space-y-2">
                  <div className="mb-2 block text-white text-base">
                    <h1>Password</h1>
                  </div>
                  <TextInput
                    id="password1"
                    type="password"
                    required={true}
                    value={password}
                    onChange={handlePassword}
                  />
                </div>
              </div>
              <button
                className="mt-4 relative w-full flex justify-center py-2 px-4 border border-transparent text-base font-medium rounded-xl text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit"
              >
                Masuk
              </button>
            </form>
          </div>
        </div>
      </div>
      <style>{`
                .h-screen {
                    background: linear-gradient(90deg
                      ,#161616 29px,transparent 1%) center,linear-gradient(#161616 29px,transparent 1%) center,#3c3c3c;
                    background-size: 30px 30px;
                }
                button.mt-4{
                  box-shadow: 0 2px 20px 6px #ff9f006b;
                }
            `}</style>
    </>
  );
}
