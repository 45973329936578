import { Dialog, Transition } from "@headlessui/react";
import { React, Fragment, useState } from "react";
import ReplyAnswer from "../elements/ReplyAnswer";
import Pin from "../elements/Pin";
import DeleteAnswer from "../elements/DeleteAnswer";

import apiClient from "../services/api";
import secureLocalStorage from "react-secure-storage";
import Moment from "react-moment";
import { useToast } from "@chakra-ui/react";
import ShowFile from "./ShowFile";

export default function AnswerCard({ answer, question, setData, data }) {
  const [isOpenReply, setIsOpenReply] = useState(false);
  const [pin, setPin] = useState(answer.pinned_by !== null ? true : false);
  const token = secureLocalStorage.getItem("token");
  const user = secureLocalStorage.getItem("user");

  const toast = useToast();

  // post reply
  const [reply, setReply] = useState("");
  const [fileReply, setFileReply] = useState("");

  const handleReply = (e) => {
    setReply(e.target.value);
  };

  const handleFileReply = (e) => {
    setFileReply(e.target.value);
  };

  function closeReplyModal(e) {
    e.preventDefault();
    apiClient
      .post(
        `/questions/${question.id}/answers/${answer.id}/comments`,
        {
          title: reply,
          file: fileReply,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const newAnswers = data.answers.map((ans) => {
          if (ans.id === answer.id) {
            return {
              ...ans,
              comments: [
                ...answer.comments,
                {
                  ...response.data.data,
                  member_image: user.member_image,
                  user_fullname: user.user_fullname,
                  user_role: user.user_role,
                },
              ],
            };
          } else {
            return ans;
          }
        });

        // (newArray);

        setData((data) => {
          return {
            ...data,
            answers: [...newAnswers],
          };
        });

        toast({
          title: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    setReply("");
    setFileReply("");
    setIsOpenReply(false);
  }

  function cancelModal() {
    setReply("");
    setFileReply("");
    setIsOpenReply(false);
  }

  function openReplyModal() {
    setIsOpenReply(true);
  }

  return (
    <>
      <div className="flex gap-5 items-center justify-between mb-3">
        <div className="flex gap-3 items-center">
          <img
            className="border border-white w-10 h-10 md:w-12 md:h-12"
            style={{ borderRadius: "50%" }}
            src={
              "https://urroacademy.com/uploads/profile/" + answer.member_image
            }
            alt=""
          />
          <div>
            <h5 className="w-full text-gray-800 font-bold text-sm md:text-lg">
              {/* UA Mentor */}
              {answer.user_fullname}
            </h5>
            <h5 className="w-full text-gray-400 font-light text-xs">
              {/* Menjawab 15 jam yang lalu */}
              <Moment fromNow>{answer.updated_at}</Moment>
            </h5>
          </div>
        </div>
        {pin && (
          <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ">
            Terverifikasi
          </span>
        )}
      </div>
      {answer.file_name && <ShowFile fileName={answer.file_name} />}
      <div className="answer-text px-2 text-xs md:text-sm font-light md:leading-5 mb-2">
        {answer.title}
      </div>
      <div className="flex gap-2 px-2 font-light items-baseline">
        {/* {{-- Button reply --}} */}
        <button
          onClick={openReplyModal}
          type="button"
          style={{ fontSize: "12px" }}
        >
          Balas
        </button>
        {(user?.user_role === 1 || user?.user_role === 3) && (
          <>
            <DeleteAnswer
              answer={answer}
              question={question}
              setData={setData}
              data={data}
            />
            <Pin
              answer={answer}
              question={question}
              pin={pin}
              setPin={setPin}
            />
          </>
        )}
        {/* {{-- end Button reply --}} */}
        {/* @foreach ($answers->comments as $comments) */}
        <ReplyAnswer
          question={question}
          answer={answer}
          data={data}
          setData={setData}
        />
        {/* @endforeach */}
      </div>
      {/* Modal Reply */}
      <Transition appear show={isOpenReply} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={cancelModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            onClose={closeReplyModal}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form
                    className="space-y-6"
                    action="#"
                    onSubmit={closeReplyModal}
                  >
                    {/* <div>
                      <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                        Upload foto atau video
                      </label>
                      <label
                        htmlFor="file"
                        className="upload bg-bgPrimary block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-10 w-10 md:h-12 md:w-12 text-primary"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                          />
                        </svg>
                      </label>
                      <input
                        id="fileReply"
                        type="file"
                        // required={true}
                        value={fileReply}
                        name="fileReply"
                        onChange={handleFileReply}
                        accept="image/*,video/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div> */}
                    <div>
                      <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                        Balasan
                      </label>
                      <input
                        id="reply"
                        type="text"
                        required={true}
                        value={reply}
                        name="reply"
                        onChange={handleReply}
                        placeholder="Komentar..."
                        className="bg-bgPrimary border-2 border-orange-200 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-0 focus:outline-none focus:border-primary focus:border-2"
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full text-primary bg-bgPrimary hover:bg-orange-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Submit
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
