import { React, useEffect, useState } from "react";
import "../assets/css/App.css";
import Footer from "../parts/Footer";
import Header from "../parts/Header";
import AnswersCard from "../parts/AnswersCard";
import { useParams } from "react-router-dom";

import apiClient from "../services/api";
import secureLocalStorage from "react-secure-storage";
import Loading from "../elements/Loading";

const Details = () => {
  let { id } = useParams();
  const [data, setData] = useState({});
  const token = secureLocalStorage.getItem("token");
  const user = secureLocalStorage.getItem("user");
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    apiClient
      .get(`/detail/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setIsloading(false);
      })
      .catch((err) => {
        // err;
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <Header token={token} user={user} searchBar={false} />
      <div className="md:max-w-5xl mx-auto py-20">
        <div className="block md:grid md:grid-cols-5 md:gap-10">
          <div className="w-full md:grid md:col-span-3 gap-y-3 space-y-3 px-2 md:p-0">
            <AnswersCard data={data} setData={setData} />
          </div>
          <div className="max-w-sm md:grid md:col-span-2 none md:fixed md:right-1/7 pt-8">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
