import { React, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Moment from "react-moment";

import Like from "../elements/Like";
import Reply from "../assets/images/Chat.svg";
import ShowFile from "../elements/ShowFile";

export default function QuestionsCardInner({ question, verified }) {
  const [isLike, setIsLike] = useState(question.is_liked);

  return (
    <>
      <div
        key={question.id}
        className="bg-white drop-shadow rounded-md p-3 md:p-4"
      >
        <div className="flex gap-5 items-center justify-between mb-3">
          <div className="flex gap-4 items-center">
            <img
              className="border border-white w-10 h-10 md:w-12 md:h-12"
              style={{ borderRadius: "50%" }}
              src={
                "https://urroacademy.com/uploads/profile/" +
                question.member_image
              }
              alt=""
            />
            <div>
              <h5 className="w-full text-gray-800 font-bold text-sm md:text-lg">
                {question.user_fullname}
              </h5>
              <h5 className="w-full text-gray-400 font-light text-xs">
                {/* Menjawab 15 jam yang lalu */}
                <Moment fromNow>{question.updated_at}</Moment>
              </h5>
            </div>
          </div>
          {verified && (
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ">
              Terverifikasi
            </span>
          )}
        </div>
        <div className="question-text text-sm sm:text-lg px-2 font-semibold mb-1">
          <Link
            to={`/details/${question.id}`}
            state={question}
            className="leading-3"
          >
            {question.file_name && <ShowFile fileName={question.file_name} />}
            {question.title}
          </Link>
        </div>
        {question.answer && (
          <div className="question-text text-sm sm:text-sm px-2 font-medium bg-gray-200/50 mb-2 py-3 rounded">
            <p className="leading-relaxed font-light">
              "{question.answer?.title}"
            </p>
          </div>
        )}
        <div className="answer-text px-2 text-xs md:text-sm font-light leading-5">
          {question.pinned_by}
        </div>
        <div className="flex px-2 my-3 gap-3">
          <div className="like flex gap-2 items-center">
            <Like data={question} isLike={isLike} setIsLike={setIsLike} />
          </div>
          <Link
            to={`/details/${question.id}`}
            className="like flex gap-2 items-center"
          >
            <img className="w-4 h-4 md:w-5 md:h-5" src={Reply} alt="" />
            <p className="text-gray-600 font-light text-xs md:text-sm">
              {question.answer_count ? question.answer_count : "0"}
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}
