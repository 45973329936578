import React, { useState } from "react";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <button
      type="button"
      onClick={scrollToTop}
      className="fixed z-50 bg-primary rounded-full p-2 w-12 h-12 md:h-12 drop-shadow-lg right-3 bottom-3 md:bottom-10 md:right-13 text-white"
      style={{ display: visible ? "inline" : "none" }}
    >
      <ion-icon
        name="chevron-up-outline"
        style={{ display: visible ? "inline" : "none" }}
      ></ion-icon>
    </button>
  );
};

export default ScrollButton;
