import React from "react";
import secureLocalStorage from "react-secure-storage";
import apiClient from "../services/api";
import { useToast } from "@chakra-ui/react";

export default function DeleteAnswer({ answer, question, setData, data }) {
  const token = secureLocalStorage.getItem("token");

  const toast = useToast();

  const handleDelete = () => {
    apiClient
      .delete(`/questions/${question.id}/answers/${answer.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const newAnswers = data.answers.filter(
          (ans) => ans.id !== response.data.data.id
        );

        // (newArray);

        setData((data) => {
          return {
            ...data,
            answers: [...newAnswers],
          };
        });

        toast({
          title: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <button onClick={handleDelete} type="button" style={{ fontSize: "12px" }}>
        Hapus
      </button>
    </>
  );
}
