import { React, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import ScrollButton from "../elements/ScrollButton";

import Logo from "../assets/images/logo-u-academy.png";
import Profile from "../assets/images/profile.svg";
import Card from "../assets/images/setting.svg";
//

import Logout from "../assets/images/logout.svg";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import SearchBar from "../elements/SearchBar";
import apiClient from "../services/api";

export default function Header({ token, user, searchBar, setData }) {
  let [open, setOpen] = useState(false);

  const navigate = useNavigate();

  function handleLogout() {
    apiClient
      .get("/logout", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        secureLocalStorage.clear();

        navigate("/login");
      })
      .catch((response) => {
        // (token);
        // (response);
      });
  }

  return (
    <>
      <nav className="bg-bgNav border-gray-200 px-2 sm:px-4 py-2.5 md:py-1 fixed z-50 w-full">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="http://urroacademy.com/" className="flex items-center">
            <img src={Logo} className="ml-3 h-6 sm:h-9" alt="Flowbite Logo" />
          </a>
          <div className="flex md:order-1 md:w-4/12">
            <div className="hidden md:block w-full">
              {searchBar && <SearchBar setData={setData} />}
            </div>
            <button
              onClick={() => setOpen(!open)}
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden"
              aria-controls="navbar-search"
              aria-expanded="false"
            >
              <ion-icon
                style={{ fontSize: "23px" }}
                name={open ? "close" : "menu"}
              ></ion-icon>
            </button>
          </div>
          <div
            className={`md:flex md:items-center md:pb-0 p-2 absolute md:static bg-bgNav md:z-auto z-auto left-0 w-full md:w-auto md:pl-0 transition-all duration-500 ease-in md:order-1 ${
              open
                ? "top-12 opacity-100"
                : "top-[-490px] md:opacity-100 opacity-0"
            }`}
            id="navbar-search"
          >
            <div className="relative mt-3 md:hidden">
              {/* <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search-navbar"
                className="block p-2 pl-10 w-full font-light text-gray-400 bg-dark rounded-lg border-gray-700 focus:border-0"
                placeholder="Mau Cari Pertanyaan apa?"
              /> */}
              {searchBar && <SearchBar setData={setData} />}
            </div>
            <div className="flex flex-col md:items-center pl-0 p-2 mt-2 md:flex-row md:space-x-3 md:mt-0 md:text-sm md:font-medium">
              <div>
                <a
                  href="http://urroacademy.com/home/academy"
                  className="block py-2 pr-4 pl-3 text-white hover:text-primary"
                >
                  Academy
                </a>
              </div>
              <Menu as="div" className="relative inline-block text-left">
                <div className="hover:bg-dark rounded">
                  <Menu.Button className="inline-flex w-full md:justify-center rounded-md px-4 py-2 text-sm font-medium text-white ">
                    {user.user_fullname}
                    <ChevronDownIcon
                      className="ml-2 -mr-1 h-5 w-5 text-violet-200"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="p-2">
                      <Menu.Items>
                        <div className="p-2 rounded hover:bg-bgPrimary">
                          <a
                            href="https://urroacademy.com/member"
                            className="flex space-x-4 items-center"
                          >
                            <img
                              className="p-2 py-3"
                              src={Profile}
                              alt="profile"
                            />
                            <h3 className="font-semibold">Profil Kamu</h3>
                          </a>
                        </div>
                      </Menu.Items>
                      <Menu.Items>
                        <div className="p-2 rounded hover:bg-bgPrimary">
                          <a
                            href="https://urroacademy.com/member/afiliasi"
                            className="flex space-x-4 items-center"
                          >
                            <img
                              className="p-2 py-3"
                              src="https://urroacademy.com/assets/member/img/afiliasi.svg"
                              alt="profile"
                            />
                            <h3 className="font-semibold">Referal</h3>
                          </a>
                        </div>
                      </Menu.Items>
                      <Menu.Items>
                        <div className="p-2 rounded hover:bg-bgPrimary">
                          <a
                            href="https://urroacademy.com/member/transactions"
                            className="flex space-x-4 items-center"
                          >
                            <img
                              className="p-2 py-3"
                              src={Card}
                              alt="profile"
                            />
                            <h3 className="font-semibold">Riwayat Transaksi</h3>
                          </a>
                        </div>
                      </Menu.Items>
                      <Menu.Items>
                        <div className="p-2 rounded hover:bg-bgPrimary">
                          <button
                            onClick={handleLogout}
                            className="flex space-x-4 items-center"
                          >
                            <img
                              className="p-2 py-3"
                              src={Logout}
                              alt="profile"
                            />
                            <h3 className="font-semibold">Logout</h3>
                          </button>
                        </div>
                      </Menu.Items>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="hidden md:block">
                <img
                  className="ml-3"
                  width="40px"
                  height="40px"
                  src={
                    "https://urroacademy.com/uploads/profile/" +
                    user.member_image
                  }
                  alt="default profile"
                  style={{ borderRadius: "50%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <ScrollButton />
    </>
  );
}
