import React from "react";

import Faq from "../assets/images/faq.svg";
import Instagram from "../assets/images/instagram.svg";
import Twitter from "../assets/images/twitter.svg";

export default function Footer() {
  return (
    <>
      <div className="sticky inset-x-0 top-0 left-0 p-4 md:pl-10">
        <div className="text-3xl text-dark font-semibold mb-16">
          Hubungi Kami
        </div>
        <div className="space-y-4 max-w-xs mb-6">
          <a
            className="flex justify-between px-4 font-xl text-dark py-4 w-full rounded-lg"
            style={{ border: "1px solid #6C6C6C" }}
            href="http://wa.me/6289679629576"
          >
            <p>
              Kirim WA <span className="text-gray-600">089679629576</span>
            </p>
            <img src={Faq} alt="" />
          </a>
          <a
            className="flex justify-between px-4 font-xl text-dark py-4 w-full rounded-lg"
            style={{ border: "1px solid #6C6C6C" }}
            href="mailto:bd@urrofilms.com"
          >
            <p>
              Kirim Email{" "}
              <span className="text-gray-600">bd@urrofilms.com</span>
            </p>
            <img src={Faq} alt="" />
          </a>
        </div>
        <div className="text-xl text-dark font-semibold">
          Ikuti di media sosial
        </div>
        <div className="flex mt-6 mb-10">
          <a href="http://instagram.com/urrofilms">
            <img className="w-10 mr-4" src={Instagram} alt="" />
          </a>
          <a href="http://twitter.com/urrofilms">
            <img className="w-10 mr-4" src={Twitter} alt="" />
          </a>
        </div>
        <div className="text-xl text-dark font-semibold mb-4">Office</div>
        <a
          href="https://www.google.com/maps/place/URRO+Films/@-6.1747627,106.7907025,15z/data=!4m2!3m1!1s0x0:0xa2d4f2e130594ada?sa=X&ved=2ahUKEwjkq93am5zzAhXWF3IKHXCeAD8Q_BJ6BAhXEAM"
          className="text-sm text-dark font-light"
        >
          PT Urrofi Kreasi Indonesia <br />
          Soho Capital Podomoro City, 19th Floor, Jl. Letjen S Parman Kav. 28,
          Kelurahan Tanjung Duren Selatan, Kecamatan Grogol Petamburan, Jakarta
          Barat, 11470
        </a>
      </div>
    </>
  );
}
