import { useState } from "react";
import apiClient from "../services/api";
import slugify from "react-slugify";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

export default function SearchBar({ setData }) {
  const [name, setName] = useState("");
  const token = secureLocalStorage.getItem("token");

  const navigate = useNavigate();

  function handleSearch(e) {
    if (name === "") {
      navigate("/");
      return;
    }

    e.preventDefault();

    apiClient
      .get(`/questions/search/${slugify(name)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((response) => {
        setData([]);
      });
  }

  return (
    <form
      className="flex relative w-full items-center px-2 md:px-0"
      onSubmit={handleSearch}
    >
      <input
        type="text"
        id="search-navbar"
        className="w-full block p-2.5 font-light text-sm text-gray-400 bg-dark border-0 active:bg-dark rounded-l-lg focus:ring-0 focus:outline-none focus:border-0"
        placeholder="Mau Cari Pertanyaan apa?"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div
        className="block items-center p-2.5 pl-3 cursor-pointer bg-primary/50 rounded-r-lg "
        onClick={handleSearch}
      >
        <svg
          className="w-5 h-5 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Search icon</span>
      </div>
      <input type="submit" className="hidden" />
    </form>
  );
}
