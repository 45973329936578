import React, { useState } from "react";
import { HeartIcon } from "@heroicons/react/solid";
import apiClient from "../services/api";
import secureLocalStorage from "react-secure-storage";

const LikeButton = ({ data, setIsLike, isLike }) => {
  const token = secureLocalStorage.getItem("token");
  const [totalLikes, setTotalLikes] = useState(data.like_count);

  // (isLike)

  const handleClick = () => {
    // apiClient.post(`/questions/like/${data.id}`)
    apiClient
      .post(`/questions/like/${data.id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLike(!isLike);
        if (response.data.message === "Berhenti menyukai pertanyaan berhasil") {
          setTotalLikes(totalLikes - 1);
        } else {
          setTotalLikes(totalLikes + 1);
        }
      });
  };

  return (
    <>
      <button
        key={data.key}
        className={`like-button ${isLike && "liked"}`}
        onClick={handleClick}
      >
        <span className="likes-counter flex gap-2 font-light">
          <HeartIcon className="h-6 w-6 block stroke-3 stroke-dark text-white" />
          {totalLikes}
        </span>
      </button>
      <style>{`
                .liked .stroke-3 {
                    color: #CF0000;
                    stroke: none;
                }
            `}</style>
    </>
  );
};

export default LikeButton;
