import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import apiClient from "../services/api";

const ShowFile = ({ fileName }) => {
  const videoExtension = ["mp4", "webm"];
  const imageExtension = ["jpg", "jpeg", "png", "webp"];

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const token = secureLocalStorage.getItem("token");
  // console.log(file);

  useEffect(() => {
    apiClient
      .get(`/file/${fileName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        setIsLoading(false);
        setFile(res.data);
      });
  }, []);

  if (isLoading) {
    return "Loading...";
  }

  if (videoExtension.includes(fileName.split(".")[1])) {
    return (
      <video
        className="rounded-lg mb-3 max-w-full md:max-w-xl"
        src={URL.createObjectURL(file)}
        alt=""
        controls={true}
      />
    );
  }

  if (imageExtension.includes(fileName.split(".")[1])) {
    return (
      <img
        className="rounded-lg mb-3 max-w-full md:max-w-xl"
        src={URL.createObjectURL(file)}
        alt=""
      />
    );
  }
  return <p>Loading file error...</p>;
};
export default ShowFile;
