import React from "react";
import secureLocalStorage from "react-secure-storage";
import apiClient from "../services/api";
import { useToast } from "@chakra-ui/react";

export default function DeleteComment({
  answer,
  question,
  comment,
  setData,
  data,
}) {
  const token = secureLocalStorage.getItem("token");

  const toast = useToast();

  const handleDelete = () => {
    apiClient
      .delete(
        `/questions/${question.id}/answers/${answer.id}/comments/${comment.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // data;
        const newAnswers = data.answers.map((ans) => {
          if (ans.id === answer.id) {
            const newComments = ans.comments.filter(
              (comment) => comment.id !== response.data.data.id
            );
            return {
              ...ans,
              comments: newComments,
            };
          } else {
            return ans;
          }
        });

        setData((data) => {
          return {
            ...data,
            answers: [...newAnswers],
          };
        });

        toast({
          title: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <button
        onClick={handleDelete}
        type="button"
        style={{ fontSize: "12px" }}
        className="text-dark"
      >
        Hapus
      </button>
    </>
  );
}
