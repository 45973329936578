import React from "react";
import { TrashIcon } from "@heroicons/react/outline";
import apiClient from "../services/api";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export default function Delete({ question }) {
  const token = secureLocalStorage.getItem("token");

  const navigate = useNavigate();
  const toast = useToast();

  const handleClick = () => {
    apiClient
      .delete(`/questions/${question.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/");
      });
  };
  return (
    <>
      <button onClick={handleClick}>
        <TrashIcon className="h-6 stroke-3 stroke-dark block stroke-dark" />
      </button>
    </>
  );
}
