import React from "react";
import secureLocalStorage from "react-secure-storage";
import apiClient from "../services/api";

export default function Pin({ answer, question, pin, setPin }) {
  const token = secureLocalStorage.getItem("token");

  const handlePin = () => {
    apiClient
      .get(`/questions/${question.id}/answers/pin/${answer.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(setPin((pin) => !pin));
  };
  return (
    <>
      <button onClick={handlePin} type="button" style={{ fontSize: "12px" }}>
        {pin ? "Unpin" : "Pin"}
      </button>
    </>
  );
}
