import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function UploadFile({ handleFile }) {
  const onDrop = useCallback((acceptedFiles) => {
    handleFile(acceptedFiles[0]);
    // (acceptedFiles);
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <div
        {...getRootProps()}
        className="focus:ring-0 focus:outline-none focus:border-0 "
      >
        <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
          Upload foto atau video
        </label>
        <input
          {...getInputProps({
            // id="file"
            // type="file"
            // required={true}
            // value: file,
            // name="file"
            // onChange: (files) => (files[0]),
            // accept="image/*,video/*"
            className:
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 ",
          })}
        />
        {isDragActive ? (
          <label
            htmlFor="file"
            className="upload bg-bgPrimary block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            <p className="text-dark">Drop file disini</p>
          </label>
        ) : (
          <label
            htmlFor="file"
            className="upload bg-bgPrimary block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 md:h-12 md:w-12 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
          </label>
        )}
      </div>
      <div>
        {files.length > 0 && (
          <>
            <h4>File</h4>
            <p>{files}</p>
          </>
        )}
      </div>
    </div>
  );
}

// {
//   /* <div>
//   <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
//     Upload foto atau video
//   </label>
//   <label
//     htmlFor="file"
//     className="upload bg-bgPrimary block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
//   >
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10 md:h-12 md:w-12 text-primary"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//       strokeWidth={2}
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
//       />
//     </svg>
//   </label>
//   <input
//     id="file"
//     type="file"
//     // required={true}
//     value={file}
//     name="file"
//     onChange={handleFile}
//     accept="image/*,video/*"
//     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//   />
// </div>; */
// }
