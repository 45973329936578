import { React, useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import Footer from "../parts/Footer";
import Header from "../parts/Header";
import QuestionCard from "../parts/QuestionCard";
import Loading from "../elements/Loading";

import apiClient from "../services/api";

const Home = () => {
  const [data, setData] = useState([]);
  const token = secureLocalStorage.getItem("token");
  const user = secureLocalStorage.getItem("user");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiClient
      .get("/home", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        // err;
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <Header token={token} user={user} searchBar={true} setData={setData} />
      <div className="md:max-w-5xl mx-auto py-20">
        <div className="block md:grid md:grid-cols-5 md:gap-10">
          <div className="w-full md:grid md:col-span-3 gap-y-3 space-y-3 px-2 md:p-0">
            <QuestionCard data={data} setData={setData} />
          </div>
          <div className="max-w-sm md:grid md:col-span-2 none md:fixed md:right-1/7 pt-8">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
