import { Dialog, Transition } from "@headlessui/react";
import secureLocalStorage from "react-secure-storage";
import { Fragment, useState } from "react";

import apiClient from "../services/api";

import QuestionsCardInner from "./QuestionsCardInner";
import { useToast } from "@chakra-ui/react";
import UploadFile from "../elements/UploadFile";

export default function QuestionCard({ data, setData }) {
  const [isOpen, setIsOpen] = useState(false);
  const token = secureLocalStorage.getItem("token");
  const user = secureLocalStorage.getItem("user");

  const [file, setFile] = useState({});
  const [title, setTitle] = useState("");
  const toast = useToast();

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleFile = (file) => {
    setFile(file);
  };

  function handleSubmit(e) {
    e.preventDefault();
    // console.log(file);
    apiClient
      .post(
        "/questions",
        {
          title: title,
          file: file,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setData((data) => [
          {
            ...res.data.data,
            user_role: user.user_role,
            user_fullname: user.user_fullname,
            member_image: user.member_image,
            is_like: false,
            like_count: 0,
            answer: null,
          },
          ...data,
        ]);
        toast({
          title: res.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((res) => {
        toast({
          title: res.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    setFile({});
    setTitle("");
    setIsOpen(false);
  }
  function cancelModal() {
    setFile("");
    setTitle("");
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="md:pt-4">
        <button
          type="button"
          onClick={openModal}
          className="bg-white drop-shadow rounded-md p-4 w-full"
        >
          <div className="flex gap-5 items-center">
            <img
              className="border border-white"
              width="50px"
              height="50px"
              style={{ borderRadius: "50%" }}
              src={
                "https://urroacademy.com/uploads/profile/" + user.member_image
              }
              alt=""
            />
            <h5 className="w-full text-gray-400 text-start text-sm md:text-lg">
              Tulis Pertanyaan...
            </h5>
          </div>
        </button>
      </div>
      {/* Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={cancelModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            onClose={(e) => handleSubmit(e)}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form
                    className="space-y-6"
                    action="#"
                    onSubmit={handleSubmit}
                  >
                    <UploadFile file={file} handleFile={handleFile} />
                    <div>
                      <label
                        htmlFor="text"
                        className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300"
                      >
                        Pertanyaan
                      </label>
                      <input
                        id="title"
                        type="text"
                        required={true}
                        value={title}
                        name="title"
                        onChange={handleTitle}
                        placeholder="Pertanyaan..."
                        className="bg-bgPrimary border-2 border-orange-200 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-0 focus:outline-none focus:border-primary focus:border-2"
                      />
                    </div>
                    <button
                      // onSubmit={handleSubmit}
                      type="submit"
                      className="w-full text-primary bg-bgPrimary hover:bg-orange-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Submit
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* end modal */}

      {data.length === 0 ? (
        // ? "Tidak ada pertanyaan"
        <div className="items-center min-h-full text-center py-4 shadow-lg bg-white rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-1/3 w-1/3 m-auto stroke-black/25"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>

          <p className="font-extrabold text-black/50 mt-2">
            "Belum Ada Pertanyaan"
          </p>
        </div>
      ) : (
        data?.map((question) => (
          <QuestionsCardInner
            key={question.id}
            question={question}
            verified={question.answer?.pinned_by ? true : false}
          />
        ))
      )}

      {/* <div className="bg-white drop-shadow rounded-md p-3 md:p-4">
                <div className="flex gap-5 items-center justify-between mb-3">
                    <div className="flex gap-4 items-center">
                        <img className="border border-white w-10 h-10 md:w-12 md:h-12" style={{borderRadius: '50%'}} src={UserProfile} alt=""/>
                        <div>
                            <h5 className="w-full text-gray-800 font-bold text-sm md:text-lg">Daffa Urrofi</h5>
                            <h5 className="w-full text-gray-400 font-light text-xs">Menjawab 15 jam yang lalu</h5>
                        </div>
                    </div>
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ">Terverifikasi</span>
                </div>
                <div className="question-text text-sm sm:text-lg px-2 font-semibold mb-2">
                    <a href="/details" className="leading-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis ut ultricies pretium nunc sed turpis facilisis egestas pellentesque. Vulputate ac porta lectus facilisi dolor sodales et laoreet. Consectetur semper viverra quam neque. A, felis in amet non adipiscing enim vitae commodo?
                    </a>
                </div>
                <div className="answer-text px-2 text-xs md:text-sm font-light leading-5">
                    Lorem ipsum dolor sit amet, ulputate ac porta lectus facilisi dolor sodales et laoreet. Consectetur semper viverra quam neque. A, felis in amet non adipiscing enim vitae commo......
                </div>
                <div className="flex px-2 my-3 gap-3">
                    <div className="like flex gap-2 items-center">
                        <Like />
                    </div>
                    <a href="#" className="like flex gap-2 items-center">
                        <img className="w-4 h-4 md:w-5 md:h-5" src={Reply} alt=""/>
                        <p className="text-gray-600 font-light text-xs md:text-sm">20</p>
                    </a>
                </div>
            </div>
            <div className="bg-white drop-shadow rounded-md p-3 md:p-4">
                <div className="flex gap-5 items-center justify-between mb-3">
                    <div className="flex gap-4 items-center">
                        <img className="border border-white w-10 h-10 md:w-12 md:h-12" style={{borderRadius: '50%'}} src={UserProfile} alt=""/>
                        <div>
                            <h5 className="w-full text-gray-800 font-bold text-sm md:text-lg">Daffa Urrofi</h5>
                            <h5 className="w-full text-gray-400 font-light text-xs">Menjawab 15 jam yang lalu</h5>
                        </div>
                    </div>
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ">Terverifikasi</span>
                </div>
                <div className="question-text text-sm sm:text-lg px-2 font-semibold mb-2">
                    <a href="/details" className="leading-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis ut ultricies pretium nunc sed turpis facilisis egestas pellentesque. Vulputate ac porta lectus facilisi dolor sodales et laoreet. Consectetur semper viverra quam neque. A, felis in amet non adipiscing enim vitae commodo?
                    </a>
                </div>
                <div className="answer-text px-2 text-xs md:text-sm font-light leading-5">
                    Lorem ipsum dolor sit amet, ulputate ac porta lectus facilisi dolor sodales et laoreet. Consectetur semper viverra quam neque. A, felis in amet non adipiscing enim vitae commo......
                </div>
                <div className="flex px-2 my-3 gap-3">
                    <div className="like flex gap-2 items-center">
                        <Like />
                    </div>
                    <a href="#" className="like flex gap-2 items-center">
                        <img className="w-4 h-4 md:w-5 md:h-5" src={Reply} alt=""/>
                        <p className="text-gray-600 font-light text-xs md:text-sm">20</p>
                    </a>
                </div>
            </div> */}
    </>
  );
}
