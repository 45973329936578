import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Moment from "react-moment";

import DeleteComment from "./DeleteComment";
import secureLocalStorage from "react-secure-storage";

export default function ReplyAnswer({ question, answer, setData, data }) {
  const user = secureLocalStorage.getItem("user");
  return (
    <div className="w-full">
      <div className="w-full rounded-2xl p-2 pl-0">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center space-x-1 py-2 text-left text-sm font-light">
                <span style={{ fontSize: "12px" }}>
                  Lihat Balasan ({answer?.comments.length})
                </span>
                <ChevronDownIcon
                  className={`${open ? "rotate-180 transform" : ""} h-4 w-4`}
                />
              </Disclosure.Button>
              {answer?.comments.map((reply) => (
                <Disclosure.Panel
                  key={reply.id}
                  className="pt-4 pb-2 text-sm text-gray-500"
                >
                  <div className="flex gap-5 items-center justify-between mb-2">
                    <div className="flex gap-3 items-center">
                      <img
                        className="border border-white"
                        width="30px"
                        height="30px"
                        style={{ borderRadius: "50%" }}
                        src={
                          "https://urroacademy.com/uploads/profile/" +
                          reply.member_image
                        }
                        alt=""
                      />
                      <div>
                        <h5
                          className="w-full text-gray-800 font-bold"
                          style={{ fontSize: "12px" }}
                        >
                          {reply.user_fullname}
                        </h5>
                        <h5
                          className="w-full text-gray-400 font-light text-xs"
                          style={{ fontSize: "12px" }}
                        >
                          <Moment fromNow>{reply.updated_at}</Moment>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    className="answer-text font-light text-sm mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {reply.title}
                  </div>
                  {(user?.user_role === 1 || user?.user_role === 3) && (
                    <DeleteComment
                      answer={answer}
                      question={question}
                      comment={reply}
                      setData={setData}
                      data={data}
                    />
                  )}
                </Disclosure.Panel>
              ))}
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
