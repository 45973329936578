import { Dialog, Transition } from "@headlessui/react";
import { React, Fragment, useState } from "react";
import Like from "../elements/Like";
// import ReplyAnswer from "../elements/ReplyAnswer";
import AnswerCard from "../elements/AnswerCard";

import Reply from "../assets/images/Chat.svg";
import apiClient from "../services/api";
import secureLocalStorage from "react-secure-storage";
import Moment from "react-moment";
import Delete from "../elements/Delete";
import { useToast } from "@chakra-ui/react";
import UploadFile from "../elements/UploadFile";
import ShowFile from "../elements/ShowFile";

export default function AnswersCard({ data, setData }) {
  const user = secureLocalStorage.getItem("user");
  const token = secureLocalStorage.getItem("token");

  const [isOpen, setIsOpen] = useState(false);
  const [isLike, setIsLike] = useState(data.is_liked);

  const toast = useToast();
  // post answers
  const [answers, setAnswers] = useState("");
  const [fileAnswers, setFileAnswers] = useState({});

  const handleAnswers = (e) => {
    setAnswers(e.target.value);
  };

  const handleFileAnswers = (file) => {
    setFileAnswers(file);
  };

  function handleSubmit(e) {
    e.preventDefault();
    apiClient
      .post(
        `/questions/${data.id}/answers/`,
        {
          title: answers,
          file: fileAnswers,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setData((data) => {
          return {
            ...data,
            answers: [
              ...data.answers,
              {
                ...response.data.data,
                pinned_by: null,
                user_role: user.user_role,
                user_fullname: user.user_fullname,
                member_image: user.member_image,
                comments: [],
              },
            ],
          };
        });
        toast({
          title: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((res) => {
        toast({
          title: res.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    setAnswers("");
    setFileAnswers({});
    setIsOpen(false);
    // window.location.reload(true);
  }

  function openModal() {
    setAnswers("");
    setFileAnswers("");
    setIsOpen(true);
  }

  function cancelModal() {
    setIsOpen(false);
  }

  return (
    <>
      <nav className="flex my-3 md:mt-5 px-2 md:p-0" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a
              href="https://urroacademy.com/"
              className="inline-flex items-center text-sm md:text-base font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              Home
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-5 h-5 md:w-6 md:h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <a
                href="/"
                className="ml-1 text-sm md:text-base font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Community
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 md:w-6 md:h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="ml-1 text-sm md:text-base font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                Question
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="md:mt-0" style={{ marginTop: "0px !important" }}>
        <button
          type="button"
          onClick={openModal}
          className="bg-white drop-shadow rounded-md p-4 w-full"
        >
          <div className="flex gap-5 items-center">
            <img
              className="border border-white"
              width="50px"
              height="50px"
              style={{ borderRadius: "50%" }}
              src={
                "https://urroacademy.com/uploads/profile/" + data.member_image
              }
              alt=""
            />
            <h5 className="w-full text-gray-400 text-start text-sm md:text-lg">
              Tulis Jawaban...
            </h5>
          </div>
        </button>
      </div>
      {/* Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={cancelModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form
                    className="space-y-6"
                    action="#"
                    onSubmit={handleSubmit}
                  >
                    <UploadFile handleFile={handleFileAnswers} />
                    <div>
                      <label
                        htmlFor="text"
                        className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300"
                      >
                        Jawaban
                      </label>
                      <input
                        id="answers"
                        type="text"
                        required={true}
                        value={answers}
                        name="answers"
                        onChange={handleAnswers}
                        placeholder="Jawaban..."
                        className="bg-bgPrimary border-2 border-orange-200 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-0 focus:outline-none focus:border-primary focus:border-2"
                      />
                    </div>
                    <button
                      // onClick={handleSubmit}
                      type="submit"
                      className="w-full text-primary bg-bgPrimary hover:bg-orange-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Submit
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* end modal */}
      <div className="bg-white drop-shadow rounded-md p-3 md:p-4">
        <div className="flex gap-5 items-center justify-between mb-3">
          <div className="flex gap-3 items-center">
            <img
              className="w-10 h-10 md:w-12 md:h-12 border border-white"
              style={{ borderRadius: "50%" }}
              src={
                "https://urroacademy.com/uploads/profile/" + data.member_image
              }
              alt=""
            />
            <div>
              <h5 className="w-full text-gray-800 font-bold text-sm md:text-lg">
                {/* UA Mentor */}
                {data.user_fullname}
              </h5>
              <h5 className="w-full text-gray-400 font-light text-xs">
                {/* Pertanyaan 15 jam yang lalu */}
                <Moment fromNow>{data.updated_at}</Moment>
              </h5>
            </div>
          </div>
        </div>
        <div className="question-text px-2 text-sm md:text-lg font-semibold md:leading-6 mb-2">
          <div className="hover:underline-offset-auto">
            {data.file_name && <ShowFile fileName={data.file_name} />}
            {data.title}
          </div>
        </div>
        <div className="flex px-2 my-3 gap-3">
          <div className="like flex gap-2 items-center">
            <Like data={data} isLike={isLike} setIsLike={setIsLike} />
          </div>
          <div className="like flex gap-2 items-center">
            <img className="w-4 h-4 md:w-5 md:h-5" src={Reply} alt="" />
            <p className="text-gray-600 font-light text-xs md:text-sm">
              {/* {data.answer_count} */}
              {data.answers.length}
            </p>
          </div>
          {(user?.user_role === 1 || user?.user_role === 3) && (
            <div className="like flex gap-2 items-center">
              <Delete question={data} />
            </div>
          )}
        </div>
      </div>
      {/* {{-- Answer --}} */}
      {data.answers != false && (
        <div className="all-question">
          <div
            className="w-full bg-answer"
            style={{ borderRadius: "6px 6px 0 0" }}
          >
            <h3 className="p-3 px-5 font-bold text-xl text-white">Jawaban</h3>
          </div>
          <div className="bg-white drop-shadow rounded-md p-3 md:p-4">
            <div className="space-y-4">
              {data?.answers?.map((answer) => (
                <div key={answer.id} className="answer-member">
                  <AnswerCard
                    answer={answer}
                    question={data}
                    setData={setData}
                    data={data}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* {{-- End answer --}} */}
    </>
  );
}
